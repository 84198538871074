/* Existing styles */

.form-control {
    height: auto;
}


.form-control[type="file"] {
    color: #6c757d;
    padding: 2px;
    font-size: 14px;
    background-color: #f5f5f5;
}

.form-control[type="file"]::file-selector-button {
    background-color: #fff;
    color: #0d6efd;
    border: 2px solid #0d6efd;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 16px;
    transition: background-color 0.2s;
}

.form-control[type="file"]::file-selector-button:hover {
    background-color: #f8f9fa;
}

/* New styles extracted from inline CSS */
.back-button {
    background-color: white;
    border-radius: 50%;
    height: 35px;
    width: 38px;
    cursor: pointer;
}

.campaign-title {
    font-size: 16px;
    font-weight: 600;
}

.label-style {
    font-size: 16px;
    color: #495057;
    margin-left: 8px;
    font-weight: 500;
}

.checkbox-container {
    background-color: #F6F6F6;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #dee2e6;
    width: 100%;
}

.custom-checkbox {
    transform: scale(1.3);
    margin: 5px 20px;
}

.icon-button {
    background: none;
    border: none;
    padding: 8px;
    border-radius: 50%;
    margin-left: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.icon-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.customer-row {
    display: flex;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 8px;
}

.file-drop-zone {
    background-color: #F6F6F6;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    padding: 25px 20px;
}

.attachments-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 13px;
}

.file-input-container {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 2px;
}

.selected-customer-section {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 13px;
    padding: 10px;
}

.file-preview-image {
    max-width: 50%;
    height: 200px;
    border-radius: 5px;
}

.customer-section-title {
    font-size: 26px;
    font-weight: 600;
}

.customer-selection-label {
    font-size: 14px;
    color: grey;
}

/* Icon specific colors */
.whatsapp-icon {
    color: #25D366;
}

.sms-icon {
    color: #0d6efd;
}

.email-icon {
    color: #6c757d;
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
    /* border-top-left-radius: 10px;
    border-bottom-left-radius: 10px; */
    border-radius: 5px;
}


/* .react-datepicker-wrapper {
    width: 93% !important;
} */

.new-campaign-date>.react-datepicker-wrapper {
    width: 100% !important;
}

.new-appointment-date>.react-datepicker-wrapper {
    width: 100% !important;
    /* display: block; */
}
.dashboard-card {
  width: 800px;
  margin: 20px;
  border: 0.5px solid #ccc;
  border-radius: 7px;
}

.count-card {
  border: 1px solid #ccc;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  font-family: "Calibri", sans-serif;
  color: black;
  border-radius: 7px !important;
}

.dashboard-card-body {
  padding: 20px;
}

.current-column {
  border-right: 1px solid #a5a0a0;
  height: 0px;
}
.current-column2 {
  margin-top: auto;
  margin-bottom: 150px;
  border-left: 1px solid #a5a0a0;
  height: 300px;
}

/* tab card */
.tab-card {
  width: 800px;
  border: 1px solid #ccc;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}

.tab-card-body {
  padding: 20px;
}

.body-row {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.body-row:hover {
  background-color: #f0ffff;
}

/* dropdown */

.dropdown-table {
  width: 100%;
  border-collapse: collapse;
}

.top-bar-icons {
  position: relative;
  z-index: 1000;
}

.card-container {
  position: absolute;
  top: 80%;
  left: 18%;
  width: 12%;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

/* hover row */
.hover-row:hover {
  background-color: #e0e0e0;
  width: 132px;
}

/* -- */
.custom-dropdown-menu {
  position: absolute;
  z-index: 999;
}

/* In your CSS file or style section */
.new-dropdown-toggle {
  border: none; /* Remove the border */
  outline: none; /* Remove the outline */
  box-shadow: none; /* Remove any box shadow */
}

/* In your CSS file or style section */
.new-dropdown-toggle {
  border: none; /* Remove the border */
  outline: none; /* Remove the outline */
  box-shadow: none; /* Remove any box shadow */
}

.custom-progress-bar {
  display: flex;
  overflow: hidden;
  height: 20px;
  background-color: #eee; /* Light gray background */
  border-radius: 1px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.progress-bar {
  text-align: center;
  line-height: 20px;
  color: #fff;
  font-size: 12px;
  transition: width 0.1s ease; /* Smooth width transition */
}

.progress-bar-success {
  background-color: #28a745; /* Green background for success */
}

.progress-bar-danger {
  background-color: #dc3545; /* Red background for danger */
}
.dropdown-container {
  position: absolute;
  top: 20px;
  right: 20px;
}

.dropdown {
  padding: 10px;
  font-size: 12px;
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: white;
  color: #333;
  cursor: pointer;
  width: 160px; /* Adjust width as needed */
  height: 40px;
}

.dropdown:hover {
  background-color: #d9d9d9;
}

.dropdown:focus {
  outline: none;
  border-color: dodgerblue;
  box-shadow: 0 0 5px dodgerblue;
}

/* Style for selected option */
.selected-option {
  font-weight: bold;
  color: dodgerblue;
}
.cardhead {
  width: "48%";
  height: "180px";
  background-color: "white";
}
.card-body {
  width: 100%;
  /* background-color: red; */
}

.childcard {
  width: "260px";
  height: "110px";
}

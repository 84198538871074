
   /* OrderTracking.css */
.progress-snake {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    padding: 20px 10px;
    margin: 0 auto;
    max-width: 1200px;
  }
  
  .step-wrapper {
    width: 33.33%;
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .step-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .step-title {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }
  
  .step-circle {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #f8f9fa;
    border: 3px solid #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: all 0.3s ease;
    margin: 15px 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    cursor: pointer;
  }
  
  .step-circle.active {
    background-color: #3498db;
    border-color: #2980b9;
    transform: scale(1.1);
  }
  
  .step-circle.active i {
    color: white;
  }
  
  .step-circle i {
    font-size: 24px;
    color: #666;
    transition: all 0.3s ease;
  }
  
  /* Connect steps with lines */
  .step-wrapper:not(:nth-child(3n))::after {
    content: '';
    position: absolute;
    height: 3px;
    background-color: #e0e0e0;
    top: 44%;
    width: calc(100% - 140px);
    right: -120px;
    z-index: 0;
  }
  
  
  /* Tooltip styling */
  .step-tooltip {
    position: absolute;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    white-space: nowrap;
    z-index: 1000;
  }
  
  .step-tooltip::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px 5px 0;
    border-style: solid;
    border-color: #333 transparent transparent;
  }
  
  .step-circle:hover .step-tooltip {
    opacity: 1;
    visibility: visible;
    top: -35px;
  }
  
  /* File upload styling */
  .file-upload-wrapper {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .file-upload {
    display: none;
  }
  
  .file-upload-btn {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #c4bfbf;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 8px;
  }
  
  .file-upload-btn:hover {
    background-color: #3498db;
    transform: scale(1.1);
  }
  
  .btn-xs {
    padding: 4px 10px;
    font-size: 12px;
    border-radius: 4px;
    opacity: 0.5;
    pointer-events: none;
    transition: all 0.3s ease;
  }
  
  .btn-xs.active {
    background-color: #3498db;
    color: white;
    opacity: 1;
    pointer-events: auto;
  }
  
  .action-popup {
    position: absolute;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0,0,0,0.15);
    padding: 20px;
    z-index: 1000;
    min-width: 320px;
    display: none;
    transform: translateX(-50%); /* Center the popup horizontally relative to the circle */
    max-height: 200px;
    overflow: scroll;
    scroll-behavior: smooth;
    scrollbar-width: thin;
  }
  
  .action-popup::before {
    content: '';
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 0 8px 8px;
    border-style: solid;
    border-color: transparent transparent white;
  }
  
  .action-popup.show {
    display: block;
  }
  .file-info {
    margin-bottom: 15px;
    margin-top: 5px;;
  }
  
  .file-info > div {
    margin-bottom: 8px;
  }
  
  .file-info i {
    margin-right: 8px;
    color: #666;
  }
  
  /* Responsive styles */
  @media (max-width: 991px) {
    .step-wrapper {
      width: 50%;
    }
    
    .step-wrapper:not(:nth-child(2n))::after {
      width: calc(100% - 140px);
    }
    
    .step-wrapper:nth-child(2n)::after {
      display: none;
    }
  }
  
  @media (max-width: 767px) {
    .step-wrapper {
      width: 100%;
    }
    
    .step-wrapper::after {
      display: none;
    }
  }
  
  /* Back button styling */
  .back-button {
    cursor: pointer;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .arrow-child {
    font-size: 20px;
  }
  
  /* Card styling */
  .card-style {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 8px;
  }

  @media (max-width: 768px) {
    .action-popup {
      position: fixed;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%);
    }
  }

  .message-box{
    width: 100% !important;
    min-height: 100px;
    resize: vertical;
    background-color: #f5f5f5 !important;
  }

  .next-followup-date{
    width: 100% !important;
  }

  .next-followup-date>.react-datepicker-wrapper {
    width : 100% !important;
   }

   .modal-header>.close>span{
    font-size: xx-large;
    background-color: #f5f5f5 !important;
    padding: 2px 10px 2px 10px;
    border-radius: 20%;
   }

   .modal-footer>.btn-secondary{
    background-color: #5a6268 !important;
   }

   .modal-body{
    padding: 3rem !important;
   }

   .step-circle.completed {
    background-color: #2ecc71 !important; /* Green color */
    border-color: #27ae60 !important;
    transform: scale(1.05) !important;
  }
  
  .step-circle.completed i {
    color: white !important;
  }

  .fetched-file{
    border-bottom: 1px solid #9e9e9e;
    margin-bottom: 3px !important;
  }


  .popup-actions{
    margin: 8px 5px;
  }
